module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pflegebörse | 24 Stunden Betreuung zu Hause | Österreichische Agentur","short_name":"Pflegebörse","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/blume.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f06bfc48695efc23d7aba3b7f450ddda"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
